<template>
  <CsTableCard
    :loading="loading"
    card-header="Elenco Gruppi"
    :rows="rows"
    :columns="columns"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :props="props"
        :show-actions="canDetails || canDelete"
      >
        <cs-table-action-item-details
          :show="canDetails"
          :row="props.row"
          @click="detailRow"
        />
        <cs-table-action-item-delete
          :show="canDelete"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Numero Utenti',
          field: row => row.utenti.length,
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canDetails: this.$grants.GRUPPI_VISUALIZZA.can,
      canDelete: this.$grants.GRUPPI_ELIMINA.can,
    }
  },
  created() {
    this.$remote.gruppi.all()
      .then(result => { this.rows = result.data })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    detailRow(params) {
      this.$routing.GRUPPI_UTENTI_ELENCO.push({ id: params.id })
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.gruppi.delete(params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
